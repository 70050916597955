.grpContact {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  -webkit-transform: translateY(-50%) translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

input {
    display: block;
    box-sizing: border-box;
    width: 60vw;
    border-radius: 4px;
    border: 1px solid gray;
    /* border: none; */
    padding: 10px 15px;
    margin-top: 5px;
    /* margin-bottom: 30px; */
    font-size: 14px;
    /* box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .2); */
}

textarea {
  display: block;
  box-sizing: border-box;
  width: 60vw;
  border-radius: 4px;
  border: 1px solid gray;
  /* border: none; */
  padding: 10px 15px;
  margin-top: 5px;
  font-size: 14px;
  height: 30vh;
  /* box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .2); */
}
.errorMSG {
  margin: 0;
  color: red;
  font-size: small;
}

#submit {
  margin-top: 100px;
  background-color: black;
  border: none;
  cursor: pointer;
  color: white;
  /* box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .2); */
}

@media screen and (max-width:499px) {
  input, textarea {
    width: 75vw;
  }

  #submit {
    margin-top: 50px;
  }
}

@media screen and (min-width:500px) and (max-width:999px) {
  input, textarea {
    width: 75vw;
  }
}