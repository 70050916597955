.rightitem {
    /* position: absolute;
    top: 47%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    -webkit-transform: translateY(-50%) translateX(-50%); */
}

.grid {
  width: 60vw;
  margin-top: 30px;
  display: grid;
  gap: 90px;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  position: absolute;
  top: 47%;
  left: 12%;
  transform: translateY(-50%) translateX(-10%);
  -webkit-transform: translateY(-50%) translateX(-10%);
}


.icon_detail {
  object-fit: cover;
  width: 140px;
  height: 140px;
  border-radius: 30px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .2);
}

.name_detail {
  margin-left: 20px;
}

.kind_detail {
  margin-left: 23px;
  color: gray;
}

.detail {
  display: flex;
  justify-content: start;
}

.logo {
    margin: 10px;
    width: 20px;
    height: 20px;
}

#close {
  position: absolute;
  top: 0px;
  right: 10px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.item {
  margin-top: 20px;
}

.title {
  display: flex;
  align-items: center;
}

.text {
  margin-top: 0;
  margin-left: 30px;
}

.linktag {
  text-decoration: none;
  color: black;
}

.link {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 13px;
  margin-left: 20px;
  border-radius: 100px;
  /* border: 1px black solid; */
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .2);
  background-color: white;
}

.choice {
  /* height: 60vh; */
  width: 60vw;
  position: absolute;
  top: 47%;
  left: 12%;
  transform: translateY(-50%) translateX(-10%);
  -webkit-transform: translateY(-50%) translateX(-10%);
}

@media screen and (max-width:499px) {
  .grid {
    width: 80vw;
    gap: 40px;
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  }

  .choice {
    width: 80vw;
  }

  .icon_detail {
    width: 60px;
    height: 60px;
    border-radius: 10px;
  }

  .name_detail {
    margin-top: 3px;
    font-size: 17px;
  }

  .kind_detail {
    font-size: 10px;
    margin-left: 20px;
  }

  .item {
    margin-top: 20px;
  }

  #close {
    width: 20px;
    height: 20px;
  }

  .title {
    font-size: 10px;
  }
  
  .text {
    font-size: small;
    margin-left: 25px;
  }

  .logo {
    width: 15px;
    height: 15px;
  }
}

@media screen and (min-width:500px) and (max-width:699px) {
  .grid {
    width: 80vw;
    gap: 50px;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  }

  .choice {
    width: 80vw;
  }

  .icon_detail {
    width: 100px;
    height: 100px;
    border-radius: 20px;
  }

  .name_detail {
    font-size: 25px;
  }

  .item {
    margin-top: 20px;
  }
}

@media screen and (min-width:700px) and (max-width:749px) {
  .grid {
    width: 80vw;
    gap: 70px;
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  }

  .choice {
    width: 80vw;
  }
}

@media screen and (min-width:750px) and (max-width:999px) {
  .grid {
    width: 80vw;
  }

  .choice {
    width: 80vw;
  }
}