.card {
    width: 150px;
    height: 150px;
    margin: auto;
    cursor: pointer;
    text-align: center;
}

.icon {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 30px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .2);
}

.name {
    margin: 0;
    margin-top: 5px;
}

.kind {
    margin: 0;
    font-size: small;
    color: gray;
}

@media screen and (max-width:499px) {
    .card {
        width: 100px;
        height: 80px;
    }

    .icon {
        border-radius: 15px;
        width: 70px;
        height: 70px;
    }

    .name {
        font-size: small;
    }

    .kind {        
        font-size: xx-small;
    }
}

@media screen and (min-width:500px) and (max-width:699px) {
    .card {
        width: 100px;
        height: 100px;
    }

    .icon {
        border-radius: 20px;
    }

    .name {
        font-size: small;
    }

    .kind {        
        font-size: xx-small;
    }
}