#grpSkill {
    text-align: center;
    margin: 0 10px;
}

#icon {
    width: 30px;
    height: 30px;    
}

#iconBorder {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 45px;
    height: 45px;
    border-radius: 30px;
    border: 1px black solid
}

.t {
    white-space: nowrap;
    margin: 0;
}

@media screen and (max-width:650px) {

    .t {
        font-size: 3vw;
    }

    #icon {
        width: 4vw;
        height: 4vw;    
    }

    #iconBorder {
        width: 7vw;
        height: 7vw;
    }
  
  }