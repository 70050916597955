.namesocial {
    position: absolute;
    top: 15vh;
    right: 5vw;
}

#name {
  font-size: 4vw;
  text-align: end; 
}

#me {
  position: absolute;
  top: 10vh;
  left: 5vw;
  width: 25vw;
  height: 25vw;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .2);
}

#me_mobile {
  width: 90px;
  height: 90px;
  float: left;
}

#intro {
  width: 500px;
  position: absolute;
  top: 33vh;
  right: 4vw;
  background-color: lightgray;
  padding: 20px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .2);
}

#intro_mobile {
  color: gray;
}

.grpSkillall {
  position: absolute;
  top: 80vh;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  -webkit-transform: translateY(-50%) translateX(-50%);
}

.grpSkill {
  width: 60vw;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
}

.grpSkill_mobile {
  width: 85vw;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
}

#skillmemo {
  position: absolute;
  left: 0;
  top: 5px;
  margin-top: -45px;
}

#skillmemo_mobile {
  margin-top: 50px;
}

.social {
  margin-top: -40px;
  text-align: end;
}

.socialicon {
  margin: 10px;
  width: 2vw;
  height: 2vw;
}

@media screen and (max-height: 750px) {

  #intro {
    font-size: small;
    padding: 15px;
  }

  .grpSkill {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    overflow-x:scroll;
  }
}

@media screen and (min-height: 751px) and (max-height:899px) {
  .grpSkill {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    overflow-x:scroll;
  }
}

@media screen and (max-width:436px) {

  #me_mobile {
    margin-right: 10px;
    width: 50px;
    height: 50px;
  }

  .namesocial {
    /* position: absolute;
    top: 60px;
    left: 0; */

    position: absolute;
    top: 50%;
    left: 0%;
    transform: translateY(-50%) translateX(0%);
    -webkit-transform: translateY(-50%) translateX(0%);
  }

  #name {
    font-size: 20px;
    margin-top: 0;
    text-align: start;
  }

  .social {
    margin-top: -15px;
    margin-left: -10px;
    text-align: start;
    margin-bottom: 5px;
  }

  .socialicon {
    width: 15px;
    height: 15px;
    margin: 10px 10px 10px 0px;
  }

  #intro_mobile {
    font-size: 12px;
  }

  .grpSkill_mobile {
    gap: 5px;
    grid-template-columns: repeat(auto-fit, minmax(70px, 1fr));
  }
  
  #skillmemo_mobile {
    font-size: 12px;
    margin-top: 30px;
  }
}

@media screen and (min-width:437px) and (max-width:584px) {
    
  #me_mobile {
    margin-right: 20px;
    width: 60px;
    height: 60px;
  }

  .namesocial {
    /* position: absolute;
    top: 60px;
    left: 0; */

    position: absolute;
    top: 50%;
    left: 0%;
    transform: translateY(-50%) translateX(0%);
    -webkit-transform: translateY(-50%) translateX(0%);
  }

  #name {
    font-size: 30px;
    margin-top: 0;
    text-align: start;
  }

  .social {
    margin-top: -25px;
    text-align: start;
    margin-bottom: 10px;
  }

  .socialicon {
    width: 15px;
    height: 15px;
    margin: 10px 20px 10px 0px;
  }

  #intro_mobile {
    font-size: small;
  }

  #skillmemo_mobile {
    font-size: small;
    margin-top: 30px;
  }

  .grpSkill_mobile {
    gap: 5px;
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  }
}

@media screen and (min-width:585px) and (max-width:999px) {
    
    #me_mobile {
      margin-right: 20px;
    }
  
    .namesocial {
      /* position: absolute;
      top: 100px;
      left: 0; */

      position: absolute;
      top: 47%;
      left: 0%;
      transform: translateY(-50%) translateX(0%);
      -webkit-transform: translateY(-50%) translateX(0%);
    }
  
    #name {
      font-size: 40px;
      margin-top: 0;
      text-align: start;
    }
  
    .social {
      margin-top: -25px;
      text-align: start;
      margin-bottom: 15px;
    }
  
    .socialicon {
      width: 20px;
      height: 20px;
      margin: 10px 20px 10px 0px;
    }

    .grpSkill_mobile {
      gap: 5px;
      grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
    }
  }