.App {
  /* height: 100vh; */
  scroll-snap-type: y mandatory;
	overflow: scroll;
  scroll-behavior: smooth;
  -ms-overflow-style: none;    /* IE, Edge 対応 */
  scrollbar-width: none;       /* Firefox 対応 */
  display: flex;
  flex-direction: column;
  width: 100vw;
}

main {
  flex: 1;
}

.App::-webkit-scrollbar {  /* Chrome, Safari 対応 */
  display: none;
}

#i {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  -webkit-transform: translateY(-50%) translateX(-50%);
  width: 100%;
  text-align: center;
}

#job {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  -webkit-transform: translateY(-50%) translateX(-50%);
  font-size: xx-small;
  color: gray;
}

.menu {
  position: absolute;
  top: 3%;
  right: 3%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menuitem {
  margin: 10px;
  cursor: pointer;
  /* border-bottom: 1px solid black; */
}

.home {
  position: relative;
  /* height: 100vh; */
  width: 100vw;
  overflow-y: scroll;
  scroll-snap-align: start;
  -ms-overflow-style: none;    /* IE, Edge 対応 */
  scrollbar-width: none;       /* Firefox 対応 */
}

.home::-webkit-scrollbar {  /* Chrome, Safari 対応 */
  display: none;
}

.Detail {
  display: flex;
  justify-content: center;
  align-items: center;
}

.leftarea {
  width: 25vw;
  /* height: 100vh; */
  position: relative;
  margin-right: 25px;
}

.leftitem {
  width: 100%;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  -webkit-transform: translateY(-50%) translateX(-50%);
}

.right {
  /* height: 100vh; */
	scroll-snap-type: y mandatory;
	overflow: scroll;
  scroll-behavior: smooth;
  overflow-y: scroll;
  scroll-snap-align: start;
  -ms-overflow-style: none;    /* IE, Edge 対応 */
  scrollbar-width: none;       /* Firefox 対応 */
}

.right::-webkit-scrollbar {  /* Chrome, Safari 対応 */
  display: none;
}

.rightarea {
  width: 65vw;
  /* height: 100vh; */
  position: relative;
  margin-left: 25px;
  overflow-y: scroll;
  scroll-snap-align: start;
  -ms-overflow-style: none;    /* IE, Edge 対応 */
  scrollbar-width: none;       /* Firefox 対応 */
}

.rightarea::-webkit-scrollbar {  /* Chrome, Safari 対応 */
  display: none;
}

#title {
  font-size: 5vw; 
}

.title_mobile {
  font-weight: bold;
  font-size: 100%;
}

.grpPage {
  padding-left: 20px;
  padding-right: 10px;
  text-align: center;
}

.dots {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: black;
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;
}


/* スクロールのやつ */
#scroll {
  position: absolute;
  bottom: 10px;
  left: 50%;
  z-index: 2;
  display: inline-block;
  text-decoration: none;
  margin: 0;
  transform: translateY(-50%) translateX(-50%);
  -webkit-transform: translateY(-50%) translateX(-50%);
}


#scroll {
  padding-top: 60px;
}
#scroll span {
  position: absolute;
  top: 0;
  left: 50%;
  width: 30px;
  height: 50px;
  margin-left: -15px;
  border: 2px solid #000;
  border-radius: 50px;
  box-sizing: border-box;
}
#scroll span::before {
  position: absolute;
  top: 10px;
  left: 50%;
  content: '';
  width: 6px;
  height: 6px;
  margin-left: -3px;
  background-color: #000;
  border-radius: 100%;
  -webkit-animation: sdb 2s infinite;
  animation: sdb 2s infinite;
  box-sizing: border-box;
}

@media screen and (max-width:999px) {
  .leftarea {
    display: none;
  }

  .rightarea {
    width: 85vw;
  }

  .dots {
    width: 8px;
    height: 8px;
    border-radius: 8px;
  }

  .menuitem {
    font-size: 100%;
  }
}

@-webkit-keyframes sdb {
  0% {
    -webkit-transform: translate(0, 0);
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    -webkit-transform: translate(0, 20px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes sdb {
  0% {
    transform: translate(0, 0);
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    transform: translate(0, 20px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
/* スクロールのやつ */

/* 背景アニメーション */
/* .area{
  background: #4e54c8;  
  background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);  
  width: 100%;
  height:100vh;
} */

.circles{
  position: absolute;
  top: 0%;
  left: 50%;
  width: 95vw;
  height: 100vh;
  overflow: hidden;
  transform: translateY(0%) translateX(-50%);
  -webkit-transform: translateY(0%) translateX(-50%);
}

.circles li{
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  /* background: rgba(255, 255, 255, 0.2); */
  background:rgb(135, 206, 250, 0.5);
  animation: animate 25s linear infinite;
  bottom: -150px;
  
}

.circles li:nth-child(1){
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}


.circles li:nth-child(2){
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3){
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4){
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5){
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6){
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7){
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8){
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9){
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10){
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}



@keyframes animate {

  0%{
      transform: translateY(0) rotate(0deg);
      opacity: 1;
      border-radius: 0;
  }

  100%{
      transform: translateY(-1000px) rotate(720deg);
      opacity: 0;
      border-radius: 50%;
  }

}
/* 背景アニメーション */